<template>
	<div class="cont">
		<img src="../assets/background.png" class="bg-img">
		<div class="bg-wrapper">
			<!-- <div class="header-line">
				<div class="imgList-left">
					<a @click="jump">
						<img src="../assets/lIcon.png" alt="">
					</a>
				</div>
				<div class="imgList-right">
					<a :href="kefu_link">
						<img src="../assets/kefu.png" alt="">
					</a>
					<a href="/index/user/lang.html">
						<img src="../assets/lang.png" alt="">
					</a>
				</div>
			</div> -->
			
			<div class="logo">
				<img src="../assets/main_logo.png" style="width: 30vw;height: 9vh;"/>
			</div>
			<div class="inputs">
				<div class="phone">
					<input type="text" name="tel" @input="inname" :placeholder="this.please_enter"/>
				</div>
				<div class="password">
					<input type="password" name="pwd" @input="inpass" :placeholder="this.your_password"/>
				</div>
				<div class="loginBtn" @click="login">{{login_1}}</div>
				<div class="tips">
					{{no_account}}
					<i>
						<a style="color: #f24255;" @click="register">{{reg_title}}</a>
					</i>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Login",
		props: {
			msg: String,
		},
		data() {
			return {
				user: {
					tel: "",
					pwd: "",
				},
				reme: false,

				please_enter: "",
				your_password: "",
				login_1: "",
				no_account: "",
				reg_title: "",
				kefu_link: "",
				home_link: "",
			};
		},
		created() {
			// 初始化操作，如获取数据
			this.fetchData();
		},
		methods: {
			async fetchData() {
				var result = await this.$proxy.getvuelang();
				// console.log(result);
				var info = result.info;
				this.please_enter = info.please_enter;
				this.your_password = info.your_password;
				this.login_1 = info.login_1;
				this.no_account = info.no_account;
				this.reg_title = info.reg_title;
				this.kefu_link = info.kefu_link;
				this.home_link = info.home_link;

				if (typeof this.please_enter == "undefined") {
					this.please_enter = 'Please enter the platform account';
					this.your_password = 'your password';
					this.login_1 = 'login';
					this.no_account = 'No account?';
					this.reg_title = 'Register';
				}
			},
			// 获取用户名和密码
			inname(e) {
				this.user.tel = e.target.value;
			},
			inpass(e) {
				this.user.pwd = e.target.value;
			},
			// 跳转到注册
			register() {
				this.$router.push({
					path: "/register"
				});
			},
			// 跳转到语言
			lang() {
				window.open('/index/user/lang', '_self');
			},
			// 登录
			async login() {
				if (this.user.tel === "" || this.user.pwd === "") {
					this.$vs.notification({
						position: "top-center",
						icon: "<span class='material-icons' style='font-size: 24px;'>error</span>",
						color: "warn",
						title: '<span style="font-size: 18px;">Error</span>',
						text: '<span style="font-size: 14px;">Please enter account / password</span>',
					});
					return false;
				}
				// 登录接口
				var result = await this.$proxy.login(this.user);
				// console.log(result)
				if (result.code === 0) {
					//存入token
					// localStorage.setItem('token',result.data['token']);
					// alert(localStorage.token);
					const loading = this.$vs.loading({
						type: "circles",
						color: "#d5397b",
						text: "Login success",
					});
					this.hasOpenLoading = true;
					setTimeout(() => {
						loading.close();
						this.hasOpenLoading = false;
					}, 2000);
					sessionStorage.setItem('login_flag', '1');
					window.open('/index/index/home', '_self');
					// this.$router.replace("/index/index/home");
				} else {
					this.$vs.notification({
						position: "top-center",
						icon: "<span class='material-icons' style='font-size: 24px;'>error</span>",
						color: "warn",
						title: '<span style="font-size: 18px;">Error</span>',
						text: '<span style="font-size: 14px;">'+result.info+'</span>',
					});
				}
			},
		},
	};
</script>

<style scoped>
	body {
		font-size: 1rem;
		color: #333;
	}
	.cont {
		position: absolute;
		width: 100vw;
		height: 100vh;
		background-size: 100% 100%;
		bottom: 0;
		background: linear-gradient(-45deg, #7e5678, #e6c3a1);
		z-index: 2;
	}
	
	.bg-img{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.bg-wrapper{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: linear-gradient(hsla(0, 0%, 100%, 0), #7e5678);
	}
	.logo{
		position: absolute;
		width: 90vw;
		/* height: 6vh; */
		top: 20vh;
		text-align: center;
		align-items: center;
		display: flex;
		justify-content: center;
		margin: 0 5vw;
	}
	
	.imgList-left{
		position: absolute;
		z-index: 999;
		top: 20px;
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100px;
		justify-content: space-around;
	}
	
	.imgList-right {
		position: absolute;
		right: 17px;
		z-index: 999;
		top: 20px;
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100px;
		justify-content: space-around;
	}

	.imgList img {
		width: 40px;
		height: 40px;
	}
	
	.inputs {
		top: 0;
		position: absolute;
		margin-top: 35vh;
		border-radius: 50px;
		opacity: 0.85;
		padding: 0vh 10vw;
		box-sizing: border-box;
		width: 100vw;
	}

	.name {
		font-size: 14px;
		color: #fff;
		font-weight: 700;
	}
	
	input {
		background: #fff;
		border: none;
		width: 100%;
		border-radius: 6.667vw;
		padding: 7px 0;
		font-size: 16px;
		border: 0;
		height: 7vh;
		text-align:center;
	}
	.password {
		margin-top: 20px;
	}
	.loginBtn {
		margin: 3vh auto 0 auto;
		line-height: 7vh;
		text-align: center;
		margin-top: 11.333vw;
		width: 100%;
		height: 7vh;
		border-radius: 6.667vw;
		color: #fff;
		background: linear-gradient(90deg, #6529c9, #cc2996);
		font-size: 4vh;
		font-weight: bolder;
		
	}
	.tips {
		width: 100%;
		text-align: center;
		font-size: 14px;
		color: #fff;
		margin-top: 2vh;
	}
</style>
