<template>
    <div class="cont">
		<img src="../assets/background.png" class="bg-img">
		<div class="bg-wrapper">
			<div class="imgList">
				<a :href="kefu_link">
					<img src="../assets/kefu.png" alt="">
				</a>
				<a href="/index/user/lang.html">
					<img src="../assets/lang.png" alt="">
				</a>
			</div>
			<div class="inputs">
				<form class="form-signin">
					<div class="form-group">
						<div class="names"><span>*</span>{{platform_account}}</div>
						<input type="text" id="phone" name="tel" v-model="user.tel" :placeholder="please_set" 
							class="form-control" style='flex:3' aria-label="Recipient's username"/>
					</div>

					<div class="form-group">
						<div class="names"><span>*</span>{{r_password}}</div>
						<input type="password" name="pwd" v-model="user.pwd" 
							class="form-control form-control-lg text-center" :placeholder="your_password" 
							value="" required=""/>
					</div>

					<div class="form-group">
						<div class="names"><span>*</span>{{qu_newpass}}</div><input type="password"
							name="deposit_pwd" class="form-control form-control-lg text-center" 
							:placeholder="login_pass2" v-model="user.deposit_pwd" value="" required=""/>
					</div>

					<div class="form-group">
						<div class="names"><span>*</span>{{payment_password}}</div>
						<input type="password" name="self_pwd" class="form-control form-control-lg text-center" 
							:placeholder="enter_password" v-model="user.self_pwd" value="" required=""/>
					</div>

					<div class="names"><span>*</span>{{extension_code}}</div>
					<input type="text" name="invite_code" class="form-control form-control-lg text-center" 
						:placeholder="login_qr" v-model="user.invite_code" value="" required="" />
				</form>
				<div class="row mx-0 mt-4">
					<div class="registerBtn" @click="regit">{{registered_account}}</div>
					<!-- <div class="btnsss">
						<a class="btn btn-default btn-lg btn-rounded shadow btn-block form-buttom register-btn" @click="regit">{{registered_account}}</a>
					</div> -->
				</div>
				<div class="row mx-0 text-center justify-content-center" style="height:4rem;padding-top: 1rem;font-size:18px;">
					<a @click="goLogin" class="mt-4 d-block" style="color: #fff;">{{login_1}}</a>
				</div>
			</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Register",
    props: {
        msg: String,
    },
    data() {
        return {
            user: {
                user_name: "",
                tel: "",
                pwd: "",
                deposit_pwd: "",
                self_pwd: "",
                invite_code: "",
            },
            user_name: "",
            platform_account: "",
            r_password: "",
            qu_newpass: "",
            payment_password: "",
            extension_code: "",
            registered_account: "",
            login_1: "",
            enter_name: "",
            please_set: "",
            your_password: "",
            login_pass2: "",
            enter_password: "",
            login_qr: "",
        };
    },
    created() {
        // 初始化操作，如获取数据
        this.fetchData();
    },
    methods: {
        async fetchData() {
            var result = await this.$proxy.getvuelang();
            // result = JSON.parse(result);
            var info = result.info;
            this.user_name = info.user_name;
            this.platform_account = info.platform_account;
            this.r_password = info.r_password;
            this.qu_newpass = info.qu_newpass;
            this.payment_password = info.payment_password;
            this.extension_code = info.extension_code;
            this.registered_account = info.registered_account;
            this.login_1 = info.login_1;

            this.enter_name = info.enter_name;
            this.please_set = info.please_set;
            this.your_password = info.your_password;
            this.login_pass2 = info.login_pass2;
            this.enter_password = info.enter_password;
            this.login_qr = info.login_qr;

            if(typeof this.user_name == "undefined"){
                this.user_name = 'username';
                this.platform_account = 'Platform account';
                this.r_password = 'password';
                this.qu_newpass = 'Confirm password';
                this.payment_password = 'modify payment password';
                this.extension_code = 'invitation code';
                this.registered_account = 'registered account';
                this.login_1 = 'login';

                this.enter_name = 'Please enter a username';
                this.please_set = 'Please set your platform login account';
                this.your_password = 'your password';
                this.login_pass2 = 'Please confirm the password';
                this.enter_password = 'Please enter your payment password';
                this.login_qr = 'Please enter the invitation code';
            }
        },
      // 跳转到语言
        lang() {
            window.open('/index/user/lang.html','_self');
        },
        // 注册用户
        async regit() {
            if (this.user.tel == "" || this.user.pwd == "" || this.user.pwd !== this.user.deposit_pwd) {
                this.$vs.notification({
                    position: "top-center",
                    icon: "<span class='material-icons' style='font-size: 24px;'>error</span>",
                    color: "danger",
                    title: '<span style="font-size: 18px;">Error</span>',
                    text: '<span style="font-size: 14px;">The two passwords are inconsistent</span>',
                });
                return false;
            }

            if(this.user.invite_code.trim().length <= 0){
                this.$vs.notification({
                    position: "top-center",
                    icon: "<span class='material-icons' style='font-size: 24px;'>error</span>",
                    color: "danger",
                    title: '<span style="font-size: 18px;">Error</span>',
                    text: '<span style="font-size: 14px;">'+this.login_qr+'</span>',
                });
                return false;
            }

            // 注册接口
            var result = await this.$proxy.regdit(this.user);
            // console.log(result);
            if (result.code == 1) {
                this.$vs.notification({
                    position: "top-center",
                    icon: '<span class="material-icons" style="font-size: 24px;">error</span>',
                    color: "warn",
                    title: '<span style="font-size: 18px;">Error</span>',
                    text: '<span style="font-size: 14px;">'+result.info+'</span>',
                });
                return false;
            } else if (result.code == 0) {
                this.$vs.notification({
                    position: "top-center",
                    icon: '<span class="material-icons" style="font-size: 24px;">check</span>',
                    color: "success",
                    title: '<span style="font-size: 18px;">Success</span>',
                    text: '<span style="font-size: 14px;">Register success</span>',
                });
                window.open('/index/user/agreement','_self');
            }
        },

        // 去登录
        goLogin() {
            this.$router.push("/login");
        },
    },
};
</script>

<style scoped>

    .cont {
		position: absolute;
		width: 100vw;
		height: 100vh;
		background-size: 100% 100%;
		bottom: 0;
		background: linear-gradient(-45deg, #7e5678, #e6c3a1);
		z-index: 2;
    }
	.bg-img{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.bg-wrapper{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: linear-gradient(hsla(0, 0%, 100%, 0), #7e5678);
	}
	.imgList {
		position: absolute;
		right: 17px;
		z-index: 999;
		top: 20px;
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100px;
		justify-content: space-around;
	}

	.imgList img {
		width: 40px;
		height: 40px;
	}

    .inputs {
		top: 0;
		position: absolute;
		margin-top: 17vh;
		border-radius: 50px;
		opacity: 0.85;
		padding: 0vh 10vw;
		box-sizing: border-box;
		width: 100vw;
	}

    .names {
		font-size: 14px;
		color: #fff;
		font-weight: 700;
	}

    input {
		background: #fff;
		border: none;
		width: 100%;
		border-radius: 6.667vw;
		padding: 7px 0;
		font-size: 16px;
		border: 0;
		height: 7vh;
		text-align:center;
	}

    .registerBtn {
		margin: 3vh auto 0 auto;
		line-height: 7vh;
		text-align: center;
		margin-top: 11.333vw;
		width: 100%;
		height: 7vh;
		border-radius: 6.667vw;
		color: #fff;
		background: linear-gradient(90deg, #6529c9, #cc2996);
		font-size: 4vw;
		font-weight: bolder;
    }



















    

</style>
